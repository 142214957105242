import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
} from 'react-admin';

const MerchantEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm className="container">
      <TextField source="id" />
      <TextInput source="companyName" />
      <TextInput source="phone" />
      <TextInput source="email" />
      <TextInput source="website" />
      <NumberInput source="creditLimit" />
      <NumberInput source="annualTurnover" />
    </SimpleForm>
  </Edit>
);

export default MerchantEdit;
