import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Filter,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
} from 'react-admin';

import NumberField from '../../components/NumberField';

const PostFilter = (props: any) => {
  return (<Filter {...props}>
    <TextInput label="Search" source="companyName" alwaysOn />
  </Filter>);
};

const MerchantList = (props: any) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="companyName" />
      <NumberField source="creditLimit" className="align-right full-width" addLabel={false} />
      <ShowButton label="View" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);

export default MerchantList;
