import React, { useState } from 'react';
import { useDataProvider, useRefresh } from 'react-admin'
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Fade,
  Backdrop,
  Modal,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Edit = (props: any) => {
  const { toggle, showEdit, selectedStatus, selectedId, invoiceId } = props;
  const dataProvider = useDataProvider();
  const [status, statusOnChange] = useState(selectedStatus);
  const refresh = useRefresh();
  const [isLoading, setLoading] = useState(false);

  const classes = useStyles();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dataProvider.update(`invoices/${invoiceId}/transactions`, {
        id: selectedId,
        data: {
          status
        }
      })

      toggle(false);
      refresh();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showEdit}
      onClose={() => toggle(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showEdit}>
        {isLoading ? <CircularProgress /> : (
          <form onSubmit={handleSubmit}>
            <Card className={classes.paper}>
              <CardContent>
                <h3>Update Status</h3>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={selectedStatus}
                  onChange={(e: any) => statusOnChange(e.target.value)}
                >
                  <MenuItem value='accepted'>Accepted</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='rejected'>Rejected</MenuItem>
                </Select>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Update
              </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Fade>
    </Modal>
  )
}

export default Edit;
