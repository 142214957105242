import * as React from 'react';
import {
  Resource,
} from 'react-admin';


import InvoiceList from './InvoiceList';
import InvoiceShow from './InvoiceShow';
import InvoiceEdit from './InvoiceEdit';

export default (props: any) => (
  <Resource
    name="invoices"
    list={InvoiceList}
    show={InvoiceShow}
    edit={InvoiceEdit}
    {...props}
  />
);
