import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Layout,
  Sidebar,
} from 'react-admin';

const sideBarStyles = {
  root: {
    fontSize: '999px',
  }
}

const CustomSideBar = withStyles(sideBarStyles)(Sidebar);

export const CustomLayout = (props: any) => (
  <Layout
    sideBar={CustomSideBar}
    { ...props }
  />
)
