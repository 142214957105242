import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';

const styles = (theme: any) => ({
  link: {
    color: theme.palette.primary.main,
  },
});

const isUrl = (value: string) => value.startsWith('http');

const ConnectedInvoiceField = ({ classes, type, record = {} }: any) => {
  const [connectedInvoice, setConnectedInvoice] = useState<any>(null)

  async function getReference() {
    if (isUrl(record.invoiceProofData) || record.invoiceProofType === 'quickbooks') {
      return setConnectedInvoice(`${process.env.REACT_APP_API}/admin/invoice/${record.invoiceProofData}?merchantId=${record.initiatedBy.merchantId}&type=${record.invoiceProofType}`);
    }

    try {
      const result = await axios.get(`${process.env.REACT_APP_API}/admin/invoice/${record.invoiceProofData}?merchantId=${record.initiatedBy.merchantId}&type=${record.invoiceProofType}`);
      const { data: { invoiceUrl }} = result;

      setConnectedInvoice(invoiceUrl);
    } catch(e) {
      setConnectedInvoice('');
    }
  }

  useEffect(() => {
    getReference()
  }, [record.id])

  if (connectedInvoice === null) {
    return null;
  }

  return (
    <Table>
      <TableRow>
        <TableCell>
          <Typography
            component="span"
            variant="body2"
          >
            <a
              href={connectedInvoice}
              target="_blank"
            >
              View
            </a>
          </Typography>
        </TableCell>
      </TableRow>
    </Table>
  )
}

const EnhancedFirebaseDocumentField = withStyles(styles)(ConnectedInvoiceField);

EnhancedFirebaseDocumentField.defaultProps = {
  addLabel: true,
};

export default EnhancedFirebaseDocumentField;
