import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  LinearProgress
} from 'react-admin';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Edit from './components/Edit';

const useStyles = makeStyles({
  root: {
    overflowX: 'auto',
    marginTop: '-8px',
    marginRight: '-16px',
    marginLeft: '-16px',
  },
  table: {
    minWidth: 650,
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginLeft: '5px',
  }
});

const Transactions = (props: any) => {
  const [showEdit, toggleEdit] = useState(false);
  const [selectedStatus, toggleStatus] = useState(null);
  const [selectedId, toggleId] = useState(null);

  const handleEdit = (data: any) => {
    toggleEdit(true);
    toggleStatus(data.status);
    toggleId(data.id);
  }

  const { data, invoiceId } = props;
  const classes = useStyles();
  return (
    <>
      {showEdit && (
        <Edit
          toggle={toggleEdit}
          showEdit={showEdit}
          invoiceId={invoiceId}
          selectedStatus={selectedStatus}
          selectedId={selectedId}
        />
      )}
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Payment Type</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Files</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((x: any) => (
              <TableRow key={x.id}>
                <TableCell component="th" scope="row">
                  {x.id}
                </TableCell>
                <TableCell align="right">{x.amount}</TableCell>
                <TableCell align="center">{x.paymentType}</TableCell>
                <TableCell align="center">
                  {x.status}
                  {x.status === 'pending' && <CreateIcon onClick={() => handleEdit(x)} className={classes.icon} />}
                </TableCell>
                <TableCell align="center">{x.createdAt.toLocaleString()}</TableCell>
                <TableCell align="center">{x.files.map((file: any) => {
                  return (
                    <div key={file.url}>
                      <a
                        href={file.url}
                        target="_blank"
                      >
                        {file.fileName}
                      </a>
                    </div>
                  )
                })}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default Transactions;