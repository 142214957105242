import * as React from 'react';
import {
  Filter,
  Datagrid,
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  TextInput,
} from 'react-admin';

import NumberField from 'src/components/NumberField';

const PostFilter = (props: any) => {
  return (<Filter {...props}>
    <TextInput label="Search" source="referenceNumber" alwaysOn />
  </Filter>);
};

function InvoiceList(props: any) {
  return (
    <List
      {...props}
      filters={<PostFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="initiatedBy.companyName" label="Merchant" />
        <TextField sortable source="referenceNumber" />
        <NumberField source="requestedAmount" className="align-right full-width" addLabel={false} />
        <TextField source="status" />
        <TextField source="offerCreated" />
        <ShowButton label="View" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  )
}

export default InvoiceList;
