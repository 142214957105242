import * as React from "react";
import { Resource } from 'react-admin';
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';

const PostFilter = (props: any) => {
  return (<Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>);
};

export const UserList = (props: any) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="name" />
      <ShowButton label="View" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }: any) => {
  return <span>{record && record.name}</span>
}

export const UserShow = (props: any) => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="phone" />
      <TextField source="email" />
    </SimpleShowLayout>
  </Show>
);

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <TextInput source="phone" />
      <TextInput source="email" />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="phone" />
      <TextInput source="email" />
    </SimpleForm>
  </Edit>
);

export default (props: any) => (
  <Resource
    name="users"
    list={UserList}
    show={UserShow}
    create={UserCreate}
    edit={UserEdit}
    {...props}
  />
);
