import * as React from 'react';
// tslint:disable-next-line:no-var-requires
import {
  useDataProvider,
  LinearProgress,
  Labeled,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const OfferField = ({ label, record }: any) => {
  return (
    <div>
      <Labeled label={label}>
        <Typography
          component="span"
          variant="body2"
        >
          {record}
        </Typography>
      </Labeled>
    </div>
  )
}

const ShowOffer = (props: any) => {
  const [documentRecords, setDocumentRecords] = React.useState([]);
  const { subcollection, record = {} } = props;
  const dataProvider = useDataProvider();
  async function getReference() {
    try {
      const documentsRef = await dataProvider.getList(`invoices/${record.id}/${subcollection}`, {
        sort: { field: 'createdAt' },
        pagination: {
          page: 1,
          perPage: 10,
        }
      });
      const { data } = documentsRef;
      setDocumentRecords(data);
    } catch (e) {
      console.log(21, e);
    }
  }

  React.useEffect(() => {
    getReference()
  }, []);

  if (documentRecords) {
    const offer: any = documentRecords[0] || {};
    return (
      <div>
        <OfferField label="ID" record={offer.id} />
        <OfferField label="Type" record={offer.type} />
        <OfferField label="Requested Amount" record={offer.requestedAmount} />
        <OfferField label="Offered Amount" record={offer.offeredAmount} />
        <OfferField label="Accepted" record={offer.accepted} />
        <OfferField label="Created By" record={offer.createdBy} />
      </div>
    )
  }
  return <LinearProgress />
}

export default ShowOffer;
