import React, { useState } from 'react';
import { useDataProvider, useRefresh, useNotify } from 'react-admin'
import moment from 'moment';
import * as firebase from 'firebase';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fade,
  Backdrop,
  Modal,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: '1rem',
    }
  },
}));

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const OfferForm = (props: any) => {
  const { toggleShowOfferForm, showOfferForm } = props;
  const dataProvider = useDataProvider();
  const [offeredAmount, setOfferedAmount] = useState(0);
  const [dueDate, setDueDate] = useState(moment().format('YYYY-MM-DD'));
  const refresh = useRefresh();
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const classes = useStyles();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { id } = props;
    try {
      setLoading(true);

      if (!offeredAmount || offeredAmount <= 0 || !moment(dueDate).isValid()) {
        throw new Error('Please fill in correct value');
      }

      dataProvider.create(`invoices/${id}/offer`, {
        data: {
          offeredAmount: Number(offeredAmount),
          dueDate: firebase.firestore.Timestamp.fromDate(moment(dueDate).toDate()),
        }
      }).then(() => {
        refresh();
        toggleShowOfferForm(false);
      });
    } catch (error) {
      notify('Please complete the form', 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showOfferForm}
      onClose={() => toggleShowOfferForm(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showOfferForm}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.paper}>
            <CardContent>
              <h3>Create Offer</h3>
              <div className={classes.inputFieldsContainer}>
                <TextField
                  label="Amount"
                  value={offeredAmount}
                  onChange={(e: any) => setOfferedAmount(e.target.value)}
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <TextField
                  label="Due Date"
                  type="date"
                  value={dueDate}
                  onChange={(e: any) => setDueDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                Create
              </Button>
              </CardActions>
            </Card>
          </form>
      </Fade>
    </Modal>
  )
}

export default OfferForm;
