import React, { useState } from 'react';
import {
  useDataProvider,
  useRefresh,
  useQueryWithStore,
} from 'react-admin'
import Dropzone from 'react-dropzone'
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  Fade,
  Backdrop,
  Modal,
  Button,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '500px',
  },
  inputFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: '1rem',
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  filelist: {
    paddingLeft: 0,
  },
  fileListItems: {
    marginBottom: '0.25em',
    marginLeft: '1.5rem',
  },
  fileListItemsContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileListItemsDelete: {
    cursor: 'pointer',
    '&:hover': {
      fill: theme.palette.error.main,
    }
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }
}));

const documentUploadTypes = [
  {
    id: 'contract',
    name: 'Contract',
  },
  {
    id: 'invoices',
    name: 'Invoice',
  },
  {
    id: 'additional',
    name: 'Additional',
  },
  {
    id: 'transfer_record',
    name: 'Transfer record',
  },
  {
    id: 'repayment_record',
    name: 'Repayment Record',
  },
]

const DocumentUpload = (props: any) => {
  const { onClose, open, id } = props;
  const { data: invoice, error: invoiceQueryError } = useQueryWithStore({
    type: 'getOne',
    resource: 'invoices',
    payload: { id },
  });

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const refresh = useRefresh();
  const [documentType, setDocumentType] = useState<any>('');
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState<any []>([]);
  const [error, setError] = useState<null | string>(null);

  const addFiles = (selectedFiles: any[]) => {
    setFiles([
      ...files,
      ...selectedFiles,
    ])
  }

  const handleFileRemove = (fileName: any) => {
    const filterFiles = files.filter(file => file.name !== fileName);
    setFiles(filterFiles);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { id } = props;

    if (!(files.length && documentType)) {
      setError('Please select document type and upload at lease 1 file');
      return;
    }

    try {
      setLoading(true);

      const uploadedUrl = await Promise.all(files.map(async file => {
        const { data } = await dataProvider.upload(
          '',
          {
            file,
            fileName: file.name,
            path: `/merchants/${invoice.initiatedBy.merchantId}/invoices/${id}/images`,
          }
        );

        return data;
      }));

      const promises = R.zip(files, uploadedUrl).map(async ([file, url]: any) => {
        return dataProvider.create(`invoices/${id}/documents`, {
          data: {
            url,
            type: documentType,
            fileName: file.name,
          }
        });
      });

      const result = await Promise.all(promises);

      if (!result) {
        throw new Error('Upload file error');
      }

      refresh();
      onClose();

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.paper}>
            <CardContent>
              <h3>Document Upload</h3>
              <div className={classes.inputFieldsContainer}>
                <TextField
                  select
                  label="Document Type"
                  value={documentType}
                  onChange={(e: any) => setDocumentType(e.target.value)}
                >
                  {
                    documentUploadTypes.map(type => (
                      <MenuItem
                        key={type.id}
                        value={type.id}
                      >
                        { type.name }
                      </MenuItem>
                    ))
                  }
                </TextField>
                <Dropzone onDrop={(acceptedFiles: any) => addFiles(acceptedFiles)}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps({className: classes.dropzone})}>
                        <input {...getInputProps()} />
                        <p>Select files</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <ol className={classes.filelist}>
                  {
                    files.map((file: any) => (
                      <li className={classes.fileListItems}>
                        <div className={classes.fileListItemsContent}>
                          <div>{file.name}</div>
                          {
                            !isLoading && (
                              <DeleteIcon
                                className={classes.fileListItemsDelete}
                                onClick={() => handleFileRemove(file.name)}
                              />
                            )
                          }
                        </div>
                      </li>
                    ))
                  }
                </ol>
                {
                  !!error && (
                    <FormControl error={!!error}>
                      <FormHelperText>{error}</FormHelperText>
                    </FormControl>
                  )
                }
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                Upload
              </Button>
              </CardActions>
            </Card>
          </form>
      </Fade>
    </Modal>
  )
}

export default DocumentUpload;
