import React from 'react';
import Button from '@material-ui/core/Button';
import { TopToolbar, ShowButton, EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles(theme => ({
  createOfferButton: {
    marginLeft: '0.5em',
  },
}));

export const PostShowActions = (props: any) => {
  const { basePath, data = {}, customAction } = props;
  const { offerCreated = false } = data;
  const classes = useStyles();

  return (
    <TopToolbar>
      <ShowButton basePath={basePath} record={data} />
      {!offerCreated && (
        <Button
          size="small"
          color="primary"
          onClick={customAction}
          startIcon={<AddBoxIcon />}
          className={classes.createOfferButton}
        >
          Create Offer
        </Button>
      )}
    </TopToolbar>
  )
}

export const PostEditActions = (props: any) => {
  const { basePath, data = {}, customAction } = props;
  const { offerCreated = false, status } = data;
  const statusPending = status === 'pending';
  const classes = useStyles();

  return (
    <TopToolbar>
      {!!statusPending && <EditButton basePath={basePath} record={data} />}
      {!offerCreated && !!statusPending && (
        <Button
          size="small"
          color="primary"
          onClick={customAction}
          className={classes.createOfferButton}
          startIcon={<AddBoxIcon />}
        >
          Create Offer
        </Button>
      )}
    </TopToolbar>
  )
}
