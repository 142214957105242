import { createMuiTheme, createStyles } from '@material-ui/core/styles';

export const customTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#2196f3',
      light: '#6ec6ff',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Verdana',
      'Noto Sans TC',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    fontSize: 12,
  },
  overrides: {
    MuiTableRow: createStyles({
      root: {
        height: 'auto',
      },
      head: {
        height: 'auto',
      },
    }),
    MuiIconButton: createStyles({
      root: {
        height: 'auto',
      },
    }),
    MuiTableCell: createStyles({
      head: {
        fontWeight: 'bold',
        paddingBottom: '0.5rem',
      },
    }),
  },
});
