import React from 'react';
import numeral from 'numeral';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Labeled } from 'react-admin';

const styles = createStyles({
  root: {
    '&.align-right': {
      textAlign: 'right',
    },
    '&.full-width': {
      display: 'block',
      width: '100%',
    },
  },
});

function NumberField({ addLabel, label, className, classes, source, record = {} }: any) {
  const Wrapper = addLabel ? Labeled : React.Fragment;

  return (
    <Wrapper label={label ? label : source}>
      <Typography className={classNames(classes.root, className, '')}>
        {numeral(record[source]).format('0,0.00')}
      </Typography>
    </Wrapper>
  )
}

NumberField.defaultProps = {
  addLabel: true,
}

export default withStyles(styles)(NumberField);
