import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Show,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import MerchantDocumentField from 'src/components/MerchantDocumentField';

import NumberField from '../../components/NumberField';

const MerchantTitle = ({ record }: any) => {
  return <span>{record && record.companyName}</span>
}

const MerchantShow = (props: any) => (
  <Show title={<MerchantTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="companyName" />
          <TextField source="phone" />
          <TextField source="email" />
          <TextField source="website" />
          <hr />
          <NumberField source="creditLimit" />
          <hr />
          <NumberField source="annualTurnover" />
        </SimpleShowLayout>
      </Tab>
      <Tab label="Documents">
        <MerchantDocumentField label="Incomplete" merchantId={props.id} />
        <MerchantDocumentField status="completed" label="Completed" merchantId={props.id} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default MerchantShow;
