import React, { useEffect, useState } from 'react'
import { LinearProgress, useDataProvider, useRefresh, } from 'react-admin'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme: any) => ({
  link: {
    color: theme.palette.primary.main,
  },
  deleteButton: {
    '&:hover': {
      background: 'rgba(220, 0, 78, 0.08)',
    },
    '&:hover *': {
      fill: theme.palette.error.main,
    }
  },
});

const InvoiceDocumentField = ({ classes, type, record = {} }: any) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [documentRecords, setDocumentRecords] = useState<any>(null)

  async function getReference() {
    try {
      const documentsRef = await dataProvider.getList(`invoices/${record.id}/documents`, {
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        filter: {
          type,
        },
      });
      const { data } = documentsRef;

      setDocumentRecords(data);
    } catch(e) {
      console.log(21, e);
      setDocumentRecords([]);
    }
  }

  function handleFileRemove(id: string) {
    dataProvider.delete(`invoices/${record.id}/documents`, {
      id,
    }).then(() => {
      refresh();
    }).catch((error: any) => {
      console.log(error);
    })
  }

  useEffect(() => {
    getReference()
  }, [type, record.id])

  if (documentRecords === null) {
    return <LinearProgress/>
  }

  return (
    <Table>
      {
        documentRecords.map((record: any) => {
          return (
            <TableRow>
              <TableCell>
                <Typography
                  component="span"
                  variant="body2"
                >
                  <a
                    href={record.url}
                    target="_blank"
                  >
                    { record.fileName }
                  </a>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  component="span"
                  variant="body2"
                >
                  { moment(record.createdAt).format('YYYY-MM-DD HH:mm') }
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  component="span"
                  variant="body2"
                >
                  { record.createdBy }
                </Typography>
              </TableCell>
              <TableCell>
                <IconButton
                  className={classes.deleteButton}
                  onClick={() => handleFileRemove(record.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })
      }
    </Table>
  )
}

const EnhancedFirebaseDocumentField = withStyles(styles)(InvoiceDocumentField);

EnhancedFirebaseDocumentField.defaultProps = {
  addLabel: true,
};

export default EnhancedFirebaseDocumentField;
