import React, {
  HtmlHTMLAttributes,
  ReactNode,
  useRef,
  useEffect,
  useMemo
} from "react";
import classnames from "classnames";
import {
  Card,
  Avatar,
  createMuiTheme,
  Theme
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { StaticContext } from "react-router";
import { useHistory } from "react-router-dom";
import { useCheckAuth } from "ra-core";
import { Notification } from 'ra-ui-materialui';
import { customTheme } from '../styles';
import DefaultLoginForm from "./components/LoginForm";

interface Props {
  children: ReactNode;
  classes?: object;
  className?: string;
  staticContext?: StaticContext;
  theme: object;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start",
    background: '#406697',
  },
  card: {
    minWidth: 300,
    marginTop: "6em"
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center"
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<Props &
  HtmlHTMLAttributes<HTMLDivElement>> = ({
    theme,
    classes: classesOverride,
    className,
    children,
    staticContext,
    ...rest
  }) => {
    const containerRef = useRef<HTMLDivElement>();
    const classes = useStyles({ classes: classesOverride });
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);

    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
      checkAuth({}, false)
        .then(() => {
          // already authenticated, redirect to the home page
          history.push("/");
        })
        .catch(() => {
          // not authenticated, stay on the login page
        });
    }, [checkAuth, history]);

    return (
      <ThemeProvider theme={muiTheme}>
        <div className={classnames(classes.main, className)} {...rest}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            {children}
          </Card>
          <Notification />
        </div>
      </ThemeProvider>
    );
  };

Login.defaultProps = {
  theme: customTheme,
  children: <DefaultLoginForm />
};

export default Login;
