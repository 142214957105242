import * as React from "react";
import { Resource } from 'react-admin';

import MerchantList from './MerchantList';
import MerchantShow from './MerchantShow';
import MerchantEdit from './MerchantEdit';

export default (props: any) => (
  <Resource
    name="Merchants"
    list={MerchantList}
    show={MerchantShow}
    edit={MerchantEdit}
    {...props}
  />
);
