import * as React from 'react';

import {
  Edit,
  TextInput,
  SelectInput,
  FormTab,
  TabbedForm,
  EditController,
} from 'react-admin';

import { PostShowActions } from 'src/components/TopToolbar';

import OfferForm from './components/OfferForm';

const confirmationChoices = [
  { id: true, name: 'true' },
  { id: false, name: 'false' },
]

const InvoiceEdit = (props: any) => {
  const [showOfferForm, toggleShowOfferForm] = React.useState(false);

  return (
    <EditController {...props}>
      {(controllerProps: any) => {
        return (
          <>
            <OfferForm
              showOfferForm={showOfferForm}
              toggleShowOfferForm={toggleShowOfferForm}
              {...props}
            />
            <Edit
              actions={<PostShowActions customAction={() => toggleShowOfferForm(true)} />}
              {...props}
              {...controllerProps}
            >
              <TabbedForm submitOnEnter={false}>
                <FormTab label="Summary">
                  <TextInput source="referenceNumber" disabled />
                  <TextInput source="initiatedBy.type" disabled />
                  <TextInput source="requestedAmount" />
                  <TextInput source="deliveryTime" />
                  <TextInput source="status" disabled />
                  {controllerProps.record && controllerProps.record.initiatedBy.permissions && (
                    <>
                      <h4>Permissions</h4>
                      <SelectInput
                        label='Pay for Buyer'
                        source="initiatedBy.permissions.payForBuyer"
                        choices={confirmationChoices}
                        optionValue="id"
                        optionText="name"
                      />
                    </>
                  )}
                </FormTab>
                <FormTab label="Initiated By">
                  <TextInput source="initiatedBy.merchantId" label="Merchant Id" disabled />
                  <TextInput source="initiatedBy.companyName" label="Company Name" />
                  <TextInput source="initiatedBy.type" label="Type" disabled />
                  <TextInput source="initiatedBy.userId" label="User Id" disabled />
                </FormTab>
                <FormTab label="Request To">
                  <TextInput source="requestTo.companyName" label="Company Name" disabled />
                  <TextInput source="requestTo.email" label="Email" disabled />
                  <TextInput source="requestTo.phone" label="Phone" />
                </FormTab>
                <FormTab label="Confirmation">
                  <SelectInput
                    source="buyerConfirmed"
                    choices={confirmationChoices}
                    optionValue="id"
                    optionText="name"
                  />
                  <SelectInput
                    source="sellerConfirmed"
                    choices={confirmationChoices}
                    optionValue="id"
                    optionText="name"
                  />
                </FormTab>
              </TabbedForm>
            </Edit>
          </>
        )
      }}
    </EditController>
  )
}

export default InvoiceEdit;
