import React from 'react';
import { Admin } from 'react-admin';
import {
  FirebaseRealTimeSaga,
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions
} from '../helpers/react-admin-firebase';

import {
  UserResource,
  InvoiceResource,
  MerchantResource,
} from '../resources';
import {
  CustomLayout,
} from './config';
import Login from './Login';
import { customTheme } from './styles';

const config = require('./FIREBASE_CONFIG.js').firebaseConfig;

const options: RAFirebaseOptions = {
  logging: true,
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);
const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

const App: React.FC = () => {
  return (
    <Admin
      theme={customTheme}
      loginPage={Login}
      appLayout={CustomLayout}
      customSagas={[firebaseRealtime]}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <InvoiceResource name="invoices" />
      <UserResource name="users" />
      <MerchantResource name="merchants" />
    </Admin>
  );
}

export default App;
