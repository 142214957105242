import React, { useEffect, useState } from 'react'
import { LinearProgress, useDataProvider } from 'react-admin'

const GetListSubCollection = (props: any) => {
  const {
    collection,
    subcollection,
    record = {},
    classes = {},
    className,
    resource,
    sort = 'createdAt'
  } = props;

  const [documentRecords, setDocumentRecords] = useState([])
  const dataProvider = useDataProvider();

  async function getReference() {
    try {
      const documentsRef = await dataProvider.getList(`${collection}/${record.id}/${subcollection}`, {
        sort: { field: sort },
        pagination: {
          page: 1,
          perPage: 10,
        }
      });

      const { data } = documentsRef;

      setDocumentRecords(data);
    } catch (e) {
      console.log(21, e);
    }
  }

  useEffect(() => {
    getReference()
  }, [])

  if (documentRecords) {
    const children = React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        data: documentRecords
      });
    });

    return (
      <div>
        {children}
      </div>
    )
  }

  return <LinearProgress />
}

export default GetListSubCollection;
