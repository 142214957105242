import React, { useEffect, useState } from 'react'
import { LinearProgress, useDataProvider, useRefresh } from 'react-admin'
import { Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const styles = (theme: any) => ({
  link: {
    color: theme.palette.primary.main,
  },
  deleteButton: {
    '&:hover': {
      background: 'rgba(220, 0, 78, 0.08)',
    },
    '&:hover *': {
      fill: theme.palette.error.main,
    }
  },
});

const MerchantDocumentField = (props: any) => {
  const { classes, status, merchantId, record = {} } = props;
  const [documentChoices, setDocumentChoices] = useState([]);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [documentRecords, setDocumentRecords] = useState<any>(null)

  async function getReference() {
    try {
      const documentsRef = await dataProvider.getList(`merchants/${record.id}/files`, {
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        filter: {
          status,
        },
      });
      let { data } = documentsRef;

      if (!status) {
        data = data.filter((data: any) => !data.status)
      }

      setDocumentRecords(data);
    } catch(e) {
      console.log(21, e);
      setDocumentRecords([]);
    }
  }

  async function getDocuments() {
    try {
      const documentsRef = await dataProvider.getList(`merchants/${record.id}/documents`, {
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        filter: {
          status: 'incomplete',
        },
      });
      const { data } = documentsRef;

      setDocumentChoices(data);
    } catch(e) {
      console.log(21, e);
      setDocumentChoices([]);
    }
  }

  useEffect(() => {
    getReference();
    getDocuments();
  }, [status, record.id])

  if (documentRecords === null) {
    return <LinearProgress/>
  }

  const handleFileDocumentChange = async (record: any, documentId: string) => {
    if (documentId === 'default') {
      refresh();
      return;
    }

    try {
      const documentChoice: any = documentChoices.find((choice: any) => choice.id === documentId);
      const documentType = documentChoice.type;
      const updateDocumentPromise = dataProvider.update(`merchants/${merchantId}/documents/`, {
        id: documentId,
        data: {
          fileId: record.id,
          fileName: record.fileName,
          url: record.url,
          status: 'completed',
        },
      });

      const updateFilePromise = dataProvider.update(`merchants/${merchantId}/files/`, {
        id: record.id,
        data: {
          status: 'completed',
          documentType,
          documentId,
        },
      });

      await Promise.all([
        updateDocumentPromise,
        updateFilePromise,
      ]);
    } catch(e) {
      console.log(e);
    }

    refresh();
  }

  return (
    <Table>
      {
        documentRecords.map((record: any) => {
          return (
            <TableRow>
              <TableCell>
                <Typography
                  component="span"
                  variant="body2"
                >
                  <a
                    href={record.url}
                    target="_blank"
                  >
                    { record.fileName }
                  </a>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  component="span"
                  variant="body2"
                >
                  { moment(record.createdAt).format('YYYY-MM-DD HH:mm') }
                </Typography>
              </TableCell>
              <TableCell>
                {
                  !!record.documentId
                    ? (
                        <Typography
                          component="span"
                          variant="body2"
                        >
                          {record.documentType}
                        </Typography>
                      )
                    : (
                        <Select
                          onChange={(e: any) => handleFileDocumentChange(record, e.target.value)}
                          value="default"
                          disabled={!!record.documentId}
                        >
                          <MenuItem value="default">Please Select</MenuItem>
                          {
                            documentChoices.map((choice: any) =>
                              <MenuItem
                                id={choice.id}
                                value={choice.id}
                              >
                                {choice.type}
                              </MenuItem>
                            )
                          }
                        </Select>
                    )
                }
              </TableCell>
              {
                !!record.documentId && (
                  <TableCell>
                    {record.updatedBy}
                  </TableCell>
                )
              }
            </TableRow>
          );
        })
      }
    </Table>
  )
}

const EnhancedFirebaseDocumentField = withStyles(styles)(MerchantDocumentField);

EnhancedFirebaseDocumentField.defaultProps = {
  addLabel: true,
};

export default EnhancedFirebaseDocumentField;
